import React from 'react'

 const appFooter = () => {
  return (
    <div>
        <footer className="main-footer">
    <strong>Copyright &copy; 2023-2024 .</strong>
    All rights reserved.
    {/* <div className="float-right d-none d-sm-inline-block">
      <b>Version</b> 3.2.0
    </div> */}
  </footer>
    </div>
  )
}
export default appFooter;
