import React from 'react'

import AppSetting from '../appSettings/AppSettings';
const dashboard = () => {
  return (
    <>
    
      <AppSetting />
      
      </>
  )
}

export default dashboard
